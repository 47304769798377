import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngSmartContractDevelopment = loadable(() => import("../components/SmartContractDevelopment/english.js"));
const ArabicSmartContractDevelopment = loadable(() => import("../components/SmartContractDevelopment/arabic.js"));
const ChineseSmartContractDevelopment = loadable(() => import("../components/SmartContractDevelopment/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-18.webp",
      "name": "Leading Smart Contract Development Company",
      "description": "Get in touch with the top smart contract development company, Softtik, for transparent, automated, and secure solutions and services.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "115"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is Smart contract?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Smart contracts are essentially programs that run when certain conditions are met. They're usually used to automate the execution of an agreement so that all parties can be sure of the conclusion right away, without the need for any intermediaries or time waste.</p>"
        }
      }, {
        "@type": "Question",
        "name": "What companies make smart contract?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Many companies are out there who develop smart contracts, but <b>Softtik Technologies</b> is the leading smart contract development company with years of experience and technical expertise.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Is Bitcoin a smart contract?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Yes, Bitcoin is a smart contract by definition. Many think that smart contracts are only executable on overly-complex blockchains, but Bitcoin is a smart contract platform by definition.</p>"
        }
      }
      ]
    }
  ]
}

export class SmartContractDevelopment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English",
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    // else if (region == 'sa') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/smart-contract-development-services/"
            title="Smart Contract Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-18.webp"
            description="Get in touch with the top smart contract development company, Softtik, for transparent, automated, and secure solutions and services."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicSmartContractDevelopment />
                  : lang == "China"
                    ? <ChineseSmartContractDevelopment />
                    : <EngSmartContractDevelopment />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default SmartContractDevelopment;